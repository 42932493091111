'use client';

import { useEffect } from "react";
import useSubdomainRouter from "@/hooks/useSubdomainRouter";

export default function Home() {
  const { push } = useSubdomainRouter();

  useEffect(() => {
    // Redirect to the default page for the subdomain
    push("/");
  }, [push]);

  return null;
}
