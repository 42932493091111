import { useRouter } from "next/navigation";

const useSubdomainRouter = () => {
  const router = useRouter();
  const { host } = typeof window !== 'undefined' ? window.location : { host: "" };
  const { origin } = typeof window !== 'undefined' ? window.location : { origin: "" };
  const defaultPath = "/valentino";
  const subdomain = (() => {
    if (typeof window === 'undefined') return
    if (host.split(".").length === 1) return
    if (host.split(".").length >= 2 && host.split(".")[1] === "styledbyclara") {
      return host.split(".")[0];
    }
    return
  })()

  const push = (path: string) => {
    if (subdomain) {
      router.push(`/${subdomain}${path}`);
    } else {
      router.push(defaultPath + path);
    }
  };

  return { push, subdomain, host ,origin };
};

export default useSubdomainRouter;